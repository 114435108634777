import { Vue, Options } from 'vue-class-component'

@Options({
  props: {
    color: String,
    size: String
  }
})

export default class loadingButton extends Vue {
  color!: string
  size!: string
}


import { Options, Vue } from 'vue-class-component'
import clubTournaments from '@/components/Memberportal/ClubTournaments/index.vue'

@Options({
  components: {
    clubTournaments
  }
})
export default class clubTournamentsPage extends Vue {}

import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import SysClub from '@/types/SysClub'
import { SysTournamentMatchSchedule } from '@/types/SysTournament'
import MembersDataService from '@/services/MembersDataService'
import SeasonsDataService from '@/services/SeasonsDataService'
import TeamsDataService from '@/services/TeamsDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { Tournaments } from '@/services/TournamentsDataService'
import LoadingButton from '../../Frontend/LoadingButton/index.vue'

type optionsType = { name: string, id: number }

@Options({
  components: {
    LoadingButton
  }
})

export default class clubTournaments extends Vue {
  $Message: any
  clubData: SysClub = {} as SysClub
  private tournamentCategory = 'Category'
  private tournamentRaekke = { name: 'Vælg her', id: 0 }
  private tournamentRaekkeOptions = [{ name: 'Vælg her', id: 0 }]
  private clubTeams: any = []
  private todaysDate = new Date().toISOString().split('T')[0]
  private tournamentStartDateTime = this.convertFromUTCtoLocalDateTime(new Date(), 0)
  private playerOptions: any = []
  private playerValue: any = null
  private selectedPlayers: any = []
  private tournamentSeasonTextValue = { name: 'Vælg her', id: 0 }
  private tournamentSeasonTextOptions = [{ name: 'Vælg her', id: 0 }]
  private tournamentRoundsMatches = 1
  private noOfHoursBetweenMatches = 1
  private clubTournamentMatchesModal = false
  private isMatchCreationStart = false
  tournamentMatchRounds: any = []
  TodaysClubMatches: SysTournamentMatchSchedule[] = []

  get todayStart () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return this.convertFromUTCtoLocalDateTime(today, 0)
  }

  get todayEnd () {
    const today = new Date()
    today.setHours(23, 59, 59, 999)
    return this.convertFromUTCtoLocalDateTime(today, 0)
  }

  public retrieveClubInformation () {
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    let tempClub = {} as SysClub
    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        const tempMember = response.data

        if (tempMember.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })
          return
        }

        // usrgroup 3 er klubansvarlig
        if (tempMember[0].user_id.usrgroup !== 3) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })

          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }

        ClubsDataService.get(Number(tempMember[0].klubber_id.id).toString())
          .then((response) => {
            tempClub = response.data
            this.clubData = tempClub
            this.clubTeams = this.clubData.hold_id
            this.retrieveClubPlayer(Number(tempClub.id))
            this.getClubRaekke(Number(tempClub.id))
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async retrieveClubPlayer (clubId: number) : Promise<void> {
    const memberPromise = MembersDataService.asyncGetAll('', null, `klubber_id.id=${clubId}&medlem_licens_slut_gte=${this.todaysDate}`)
    await Promise.all([memberPromise])
      .then((response) => {
        const clubMemberData = response[0].data
        for (const item of clubMemberData) {
          this.playerOptions.push({
            id: Number(item.id),
            name: item.user_id.firstname + ' ' + item.user_id.lastname + ' [' + (item.medlem_licens === null ? '????' : item.medlem_licens) + ']'
          })
        }
      })
      .catch((error) => {
        console.log('ERROR', error)
      })
  }

  public selectPlayerForTournament () : void {
    const index = this.selectedPlayers.findIndex((el : any) => el.id === this.playerValue.id)
    if (index === -1) {
      this.playerValue && this.selectedPlayers.push(this.playerValue)
    } else {
      this.$Message.warning({ text: 'Player already selected' })
    }
    this.playerValue = null
  }

  public removePlayer (playerIndex: number) : void {
    this.selectedPlayers.splice(playerIndex, 1)
  }

  public getSeasonOptionsData () : void{
    SeasonsDataService.getAll()
      .then((response) => {
        const tempSeasons = response.data

        // Fill the season options array of the filter and regular options select.
        this.tournamentSeasonTextOptions = [{ name: 'Vælg her', id: 0 }]
        for (const season of tempSeasons) {
          this.tournamentSeasonTextOptions.push({ name: season.saeson_navn, id: (season.id ? Number(season.id) : 0) })
        }
        const newSeason = this.tournamentSeasonTextOptions.length > 1 ? this.tournamentSeasonTextOptions.length - 1 : 0
        this.tournamentSeasonTextValue = { name: this.tournamentSeasonTextOptions[newSeason].name, id: this.tournamentSeasonTextOptions[newSeason].id }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public async getClubRaekke (clubId: number) {
    try {
      const clubRaekkeResponse = await ClubsDataService.getClubberRaekke(clubId.toString())
      for (const data of clubRaekkeResponse.data) {
        this.tournamentRaekkeOptions.push({ name: data.raekke.raekke_navn, id: (data.raekke.id ? Number(data.raekke.id) : 0) })
      }
      const selected = this.tournamentRaekkeOptions.length > 1 ? 1 : 0
      this.tournamentRaekke = { name: this.tournamentRaekkeOptions[selected].name, id: this.tournamentRaekkeOptions[selected].id }
    } catch (error) {
      console.log('Error>>>', error)
    }
  }

  public async retriveClubTournamentCategory () {
    const tournamentCategoryId = 17
    try {
      const categoryData = await Tournaments.TournamentCategoriesDataService.get(tournamentCategoryId.toString())
      this.tournamentCategory = categoryData.data?.turneringskategori_navn ? categoryData.data?.turneringskategori_navn : 'Category'
    } catch (error) {
      console.log('Error>>>', error)
    }
  }

  public convertFromUTCtoLocalDateTime (date: any, hoursToAdd = 0) {
    const now = date
    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }

    now.setMinutes(now.getMinutes() + (hoursToAdd * 60))

    // Get formatted date and time
    const formattedDateTime = now.toLocaleString('en-GB', options)

    // Extract parts and reformat
    const [datePart, timePart] = formattedDateTime.split(', ')
    const [day, month, year] = datePart.split('/')
    const [hours, minutes] = timePart.split(':')

    const isoFormattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`
    return isoFormattedDateTime
  }

  public generateTournamentMatches (): void {
    const totalPlayers = this.selectedPlayers.length
    const totalMatches = (totalPlayers * this.tournamentRoundsMatches) / 2 // Total matches considering 2 players per match
    const allRounds = []
    const currentDate = new Date(this.tournamentStartDateTime)

    while (allRounds.flat().length < totalMatches) {
      const currentRound = []
      const startTime = (allRounds.length) === 0 ? 0 : Number(this.noOfHoursBetweenMatches)
      const matchDate = this.convertFromUTCtoLocalDateTime(currentDate, startTime)
      const usedPlayers = new Set()

      // Create a round where every player participates once
      for (let i = 0; i < totalPlayers; i += 3) {
        // Select 3 players who haven't played in this batch yet
        if (i + 2 < totalPlayers &&
            !usedPlayers.has(this.selectedPlayers[i]) &&
            !usedPlayers.has(this.selectedPlayers[i + 1]) &&
            !usedPlayers.has(this.selectedPlayers[i + 2])) {
          const player1 = this.selectedPlayers[i]
          const player2 = this.selectedPlayers[i + 1]
          const scorer = this.selectedPlayers[i + 2]

          // Add the match to the current batch
          currentRound.push({ homePlayer: player1, awayPlayer: player2, chalkerPlayer: scorer, date: matchDate })

          // Mark players as used in this batch
          usedPlayers.add(player1)
          usedPlayers.add(player2)
          usedPlayers.add(scorer)
        }
      }

      // After a full round, add the batch to allRounds
      if (currentRound.length > 0) {
        allRounds.push(currentRound)
      }

      // Rotate players: move the first player to the last position
      this.selectedPlayers.push(this.selectedPlayers.shift())
    }
    console.log('schedule...', allRounds)
    this.tournamentMatchRounds = allRounds
    this.clubTournamentMatchesModal = true
  }

  // Check for Duplicates
  public isDuplicateChalker (newPlayer: any, matches: any): boolean {
    return matches.some((match: any) => (
      match.homePlayer.id === newPlayer.id ||
      match.awayPlayer.id === newPlayer.id ||
      (match.chalkerPlayer && match.chalkerPlayer.id === newPlayer.id)
    ))
  }

  // Create teams and matches for selected players club tournament
  public async createTeamsAndMatchesClubTournament () : Promise<void> {
    if (this.tournamentMatchRounds.length > 0) {
      let matchNumber = 0
      this.isMatchCreationStart = true
      await Tournaments.TournamentMatchSchedulesDataService.getAll('kampprogram_kampnr:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 1, totalcount: 1 }, 'saeson_id.id=' + Number(this.tournamentSeasonTextValue.id).toString())
        .then((response) => {
          matchNumber = (response.data !== undefined && response.data !== null && response.data.length > 0) ? Number(response.data[0].kampprogram_kampnr) + 1 : 1
        })
      if (this.TodaysClubMatches.length > 0) {
        await this.deleteTodaysClubMatches()
      }
      for (const matchRound of this.tournamentMatchRounds) {
        for (const match of matchRound) {
          // Continue to the next match object item, if there is a skip indication in any of the player data fields (id -1 = "Oversidder")
          if (match.homePlayer.id === -1 || match.awayPlayer.id === -1 || match.chalkerPlayer.id === -1) {
            console.log('Oversidder skip.')
            continue
          }
          const homeTeam = await this.createHomeAwayPlayersTeam(match?.homePlayer)
          const awayTeam = await this.createHomeAwayPlayersTeam(match?.awayPlayer)
          if (homeTeam && awayTeam) {
            try {
              const createTournamentMatchScheduleData = {
                kampprogram_kampnr: matchNumber.toString(),
                kampprogram_slotindeks: '0',
                kampprogram_dato: (match.date ? new Date(match.date).toISOString() : null),
                kampprogram_hjemmehold: Number((homeTeam as any).id).toString(),
                kampprogram_udehold: Number((awayTeam as any).id).toString(),
                kampprogram_kommentar: '',
                saeson_id: Number(this.tournamentSeasonTextValue?.id).toString(),
                raekke_id: Number((this as any).tournamentRaekke?.id).toString()
              }

              Tournaments.TournamentMatchSchedulesDataService.create(createTournamentMatchScheduleData)
                .then(async (response) => {
                  // Create chalkerPlayer for newly created match
                  const clubMatchChalkerData = {
                    medlem_id: match.chalkerPlayer.id,
                    name: match.chalkerPlayer.name,
                    kampprogram_id: response.data.id
                  }
                  await this.createNewClubMatchChalker(clubMatchChalkerData)
                })
                .catch((err) => {
                  console.log(err)
                })
            } catch (error) {
              console.log('Error>>>', error)
            } finally {
              matchNumber++
            }
          } else {
            console.log('NOT_ABLE_TO_CREATE')
          }
        }
      }
      this.$Message.success({ text: 'Club tournament matches created' })
      this.resetVariables()
      // Go to Match list page
      this.clubTournamentMatch()
    }
  }

  public clubTournamentMatch () : void {
    this.$router.push({ name: 'ClubTournamentMatchList', params: { userSlug: this.convertUserNameToSlug() } })
  }

  // Create chalker of newly created match
  public async createNewClubMatchChalker (chalkerData: any) {
    try {
      const matchChalkerData = await ClubsDataService.createClubMatchChalker(chalkerData)
      console.log('MatchChalker_Response', matchChalkerData.data)
    } catch (error) {
      console.log('Chalker_Creation_err', error)
    }
  }

  public convertUserNameToSlug () : string {
    const user = localStorage.getItem('user')
    if (user) {
      const data = JSON.parse(user)
      if (user) {
        const id = data.id
        const userName = data.username
        return id.toString() + '-' + CommonFunctions.slugify(userName)
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  // Create team for the selected player
  public async createHomeAwayPlayersTeam (player: any): Promise<any> {
    let teamData = null
    let playerName = player.name
    playerName = playerName.split('[')[0]
    const isTeamAlreadyExists = this.clubTeams.find((team: any) => team.hold_holdnavn === playerName && team.saeson_id === this.tournamentSeasonTextValue.id && team.raekke_id === this.tournamentRaekke.id)

    if (isTeamAlreadyExists) {
      return isTeamAlreadyExists
    } else {
      // Create a new team for the player
      try {
        const newTeamData = await this.createNewTeam(playerName)
        teamData = newTeamData
      } catch (err) {
        console.error('ERROR>>>', err)
      }
      return teamData
    }
  }

  // Create a new team
  public async createNewTeam (playerName: any) : Promise<any> {
    let newTeam: any = null
    let teamData :any = null
    const createClubTeamData = {
      hold_status: true,
      hold_holdnavn: playerName,
      raekke_id: this.tournamentRaekke?.id,
      saeson_id: this.tournamentSeasonTextValue.id,
      klubber_id: this.clubData.id
    }

    try {
      const teamRes = await TeamsDataService.create(createClubTeamData)
      newTeam = teamRes.data
      teamData = {
        id: newTeam.id,
        created_at: newTeam.created_at,
        updated_at: newTeam.updated_at,
        hold_status: newTeam.hold_status,
        hold_holdnavn: newTeam.hold_holdnavn,
        klubber_id: newTeam.klubber_id.id,
        medlem_id: newTeam.medlem_id,
        raekke_id: newTeam.raekke_id.id,
        saeson_id: newTeam.saeson_id.id,
        stillinger_id: newTeam.stillinger_id
      }
      this.clubTeams.push(teamData)
    } catch (err) {
      console.error('ERROR>>>', err)
    }
    return teamData
  }

  public resetVariables () : void {
    this.tournamentRaekke = { name: 'Vælg her', id: 0 }
    this.noOfHoursBetweenMatches = 1
    this.tournamentRoundsMatches = 1
    this.selectedPlayers = []
    this.isMatchCreationStart = false
    this.clubTournamentMatchesModal = false
    this.tournamentStartDateTime = this.convertFromUTCtoLocalDateTime(new Date(), 0)
  }

  get isClubTournamentMatchesCall () : boolean {
    if (this.tournamentSeasonTextValue.id !== 0 && this.tournamentRaekkeOptions.length > 1) {
      return true
    } else {
      return false
    }
  }

  @Watch('tournamentSeasonTextValue')
  onTournamentSeasonValueChange () : void {
    this.retriveClubTournamentMatches()
  }

  @Watch('tournamentRaekke')
  onClubRaekkeValueChange () : void {
    this.retriveClubTournamentMatches()
  }

  public retriveClubTournamentMatches () {
    if (this.isClubTournamentMatchesCall) {
      const start = new Date(this.todayStart)
      const end = new Date(this.todayEnd)
      let raekkeIds = ''
      for (let i = 0; i < this.tournamentRaekkeOptions.length; i++) {
        if (i === 0) {
          continue
        }
        raekkeIds += `&_where[_or][${i}][raekke_id.id]=${this.tournamentRaekkeOptions[i].id}`
      }
      const param = 'saeson_id=' + this.tournamentSeasonTextValue.id + `&kampprogram_dato_gte=${start.toISOString()}&kampprogram_dato_lte=${end.toISOString()}` + `${raekkeIds}`
      Tournaments.TournamentMatchSchedulesDataService.getAll('kampprogram_dato:asc,kampprogram_kampnr:asc', null, param)
        .then((response) => {
          this.TodaysClubMatches = response.data
        })
        .catch((err) => {
          console.log('Error>>>', err)
        })
    }
  }

  public async deleteTodaysClubMatches () : Promise<void> {
    if (this.TodaysClubMatches && this.TodaysClubMatches.length > 0) {
      for (const match of this.TodaysClubMatches) {
        try {
          const id = match.id ? match.id : ''
          id && await Tournaments.TournamentMatchSchedulesDataService.delete(id)
        } catch (error) {
          console.log('ERROR>>>', error)
        }
      }
    }
  }

  async mounted () : Promise<void> {
    this.retrieveClubInformation()
    this.getSeasonOptionsData()
    this.retriveClubTournamentCategory()
  }
}
